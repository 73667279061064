import { render, staticRenderFns } from "./emergency.vue?vue&type=template&id=b96c42ac&scoped=true&"
import script from "./emergency.vue?vue&type=script&lang=js&"
export * from "./emergency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b96c42ac",
  null
  
)

export default component.exports